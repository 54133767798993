.custom-calendar {
  width: 100%;
  max-width: 800px;
  background: #fff;
  border: none;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
}

.custom-calendar .react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-calendar .react-calendar__navigation button {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.custom-calendar .react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: bold;
  color: #666;
  margin-bottom: 10px;
}

.custom-calendar .react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.custom-calendar .react-calendar__tile {
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, color 0.3s ease; /* Transición suave */
}

/* Estilo para días activos (seleccionados) */
.custom-calendar .react-calendar__tile--active {
  background: rgba(240, 83, 28, 1) !important; /* Naranja */
  color: white !important; /* Texto blanco */
  font-weight: bold !important; /* Texto en negrita */
  border-radius: 8px !important; /* Bordes redondeados */
  padding: 10px !important; /* Espaciado interno */
  transition: background 0.3s ease, color 0.3s ease !important; /* Transición suave */
}

/* Estilo para días activos en hover o focus */
.custom-calendar .react-calendar__tile--active:enabled:hover,
.custom-calendar .react-calendar__tile--active:enabled:focus {
  background: rgba(240, 83, 28, 0.9) !important; /* Naranja un poco más oscuro */
  color: white !important; /* Texto blanco */
}

/* Estilo para días hover */
.custom-calendar .react-calendar__tile:enabled:hover {
  background: rgba(240, 83, 28, 0.2) !important; /* Naranja claro */
  color: #333 !important; /* Texto oscuro */
}

/* Estilo para días actuales */
.custom-calendar .react-calendar__tile--now {
  background: #f4f6f8 !important; /* Gris claro */
  color: #333 !important; /* Texto oscuro */
  font-weight: bold !important; /* Texto en negrita */
}

/* Estilo para días resaltados (con eventos) */
.custom-calendar .highlight {
  background: rgba(240, 83, 28, 0.1) !important; /* Fondo naranja claro */
  color: rgba(240, 83, 28, 1) !important; /* Texto naranja */
  font-weight: bold !important; /* Texto en negrita */
}

/* Estilo para días deshabilitados */
.custom-calendar .react-calendar__tile:disabled {
  background: #f9f9f9 !important; /* Fondo gris muy claro */
  color: #ccc !important; /* Texto gris */
  cursor: not-allowed !important; /* Cursor deshabilitado */
}